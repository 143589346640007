import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  p {
    font-size: var(--fz-lg);
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  .tech-list {
    margin-top: 40px;
    font-size: var(--fz-lg);
  }

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--light-red);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'Go, Python, C++',
    'gRPC & REST',
    'Docker & K8s',
    'Microservices',
    'TypeScript & Next.js',
    'Ethereum & Hyperledger',
    'Foundry & Hardhat',
    'AWS & GCP',
    'CI/CD & GitOps',
    'Multi-threading',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hello there! I am Aditya, a passionate software engineer. I have worked as an SDE
              intern at{' '}
              <a
                href="https://careers.mercari.com/mission-values/"
                target="_blank"
                rel="noreferrer"
              >
                Mercari Japan
              </a>{' '}
              in their core backend team building foundational microservices. I have also been an
              <a
                href="https://wiki.hyperledger.org/display/CLP/Project+Plan+-+Integrate+new+BFT+protocol+with+Fabric"
                target="_blank"
                rel="noreferrer"
              >
                LFX Mentee @ Hyperledger Fabric
              </a>
              . I enjoy crafting solutions to real-world problems. My love for technology began when
              I was introduced to coding during my school years, where I worked on a few projects
              involving C++. This sparked a passion for programming that only grew stronger as I
              progressed through university.
            </p>

            <p>
              I like to explore every part of the tech sphere and have delved into variety of
              domains including blockchain, distributed systems, frontend and backend web
              development, cybersecurity, machine learning and academic research. Pushing the
              boundaries of my comfort zone is something I do with regularity, relishing the
              challenges it presents. Throughout my journey, I have honed my skills through various
              projects, internships, and collaborations. I have also worked as a research intern at{' '}
              <a href="http://www.iitkgp.ac.in/" target="_blank" rel="noreferrer">
                MUST Lab - IIT KGP
              </a>
              , an early{' '}
              <a href="https://kyte.social" target="_blank" rel="noreferrer">
                web3 start-up
              </a>
              , as web3 developer and on a few{' '}
              <a href="https://github.com/sadityakumar9211" target="_blank" rel="noreferrer">
                open source projects.
              </a>{' '}
            </p>

            <p>
              In addition to my technical abilities, I am passionate about sharing my knowledge. I
              believe that my drive to continuously learn, grow, and collaborative nature make me a
              great team player. I find great joy in writing{' '}
              <a href="https://saditya9211.hashnode.dev" target="_blank" rel="noreferrer">
                technical articles
              </a>{' '}
              and sharing my knowledge with others, as well as being active on Q&A platforms like{' '}
              <a
                href="https://stackoverflow.com/users/14769639/saditya"
                target="_blank"
                rel="noreferrer"
              >
                StackOverflow
              </a>
              .
            </p>
          </div>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
          <div className="tech-list">
            <p>Here are a few technologies I’ve been working with recently:</p>
            <ul className="skills-list">
              {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
            </ul>
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
